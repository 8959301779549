import React from "react"
import ecoworld from "../../static/logo/ecoworld-logo.png"
import ioi from "../../static/logo/IOI_Group_logo.png"
import newfields from "../../static/logo/newfields-logo.png"
import ntp from "../../static/logo/ntpgrouplogo.png"
import pavilion from "../../static/logo/pavilion-logo.png"
import setia from "../../static/logo/setia-logo.png"
import skyworld from "../../static/logo/skyworld-logo.png"

const logos = [setia, newfields, ecoworld, ioi, pavilion, ntp, skyworld]

function Clients() {
  return (
    <div>
      <h2 className="title-center">we've worked with</h2>
      <div className="row">
        {logos.map((logo, index) => (
          <div className="col col-l" key={index}>
            <div className="client-logo">
              <img src={logo} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Clients
