import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"
import Services from "../components/services"
import Clients from "../components/clients"
import Slider from "react-slick"
import Img from "gatsby-image"
import Moon from "./banner2.jpg"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      {/* Slider  */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="col-8">
          <img
            src={Moon}
            style={{
              position: "relative",
            }}
          />
          <h1 className="banner-title-text">
            SCULPTING MEANING
            <br />
            into your space
          </h1>
          <h6 className="banner-subtitle-text">
            sculptures and creative decoration <br />
            for landscape design
            <br /> and public space
          </h6>
        </div>
      </div>
      <div className="post-content">
        <Services />
        <Clients />
      </div>
      <div className="row post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <div className="col-4">
              <PostCard
                key={node.fields.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    smallPic: file(
      relativePath: { eq: "fabio-comparelli-696506-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
